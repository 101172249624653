import { Button, TextareaField, TextInputField, toaster } from 'evergreen-ui';
import { isEmpty } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { DropDown } from '../components/DropDown';
import { ImagePicker } from '../components/ImagePicker';
import { InputList } from '../components/InputList';
import { Categories } from '../constants/CATEGORIES';
import { PRODUCT_INITIAL_VAL } from '../constants/PRODUCTS';
import { ProductData } from '../models/Products';
import { AddProduct, UpdateProduct } from '../services/ProductService';
import '../styles.scss';
import { UploadFiles } from "../utils/Storage";

interface Props {
    setIsShown: (state: boolean) => void;
    editData?: any;
}

export const ProductAdd: FC<Props> = ({ setIsShown, editData }) => {
    const [values, setValues] = useState<ProductData>(PRODUCT_INITIAL_VAL);
    const [isLoading, setIsLoading] = useState(false);
    const [formValid, setFormValid] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (!isEmpty(editData)) {
            setIsEdit(true);
            setValues(editData);
        }
    }, [editData]);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const submitForm = async (e: any) => {
        setIsLoading(true);
        e.preventDefault();
        if (!(['', null].some(item => Object.values(values).includes(item)))) {
            setFormValid(true);
            let res;
            let priceList: any[] = [];
            priceList.push({ unit: 1, price: values.sellingPrice })
            for (let i = 2; i <= 10; i++) {
                priceList.push({ unit: i , price: values.sellingPrice * i - 1 });
            }
            values.priceListMap = priceList;
            if (!isEdit) {
                const url = await UploadFiles(values.images, values.productCode);
                res = await AddProduct({ ...values, images: url });
            } else {
                if (typeof values.images[0] !== 'string') {
                    const url = await UploadFiles(values.images, values.productCode);
                    res = await UpdateProduct({ ...values, images: url });
                } else {
                    res = await UpdateProduct(values);
                }
            }

            if (res.code === 200) {
                toaster.success(res.message);
                setIsShown(false);
            } else {
                toaster.danger(res.message);
            }
        } else {
            setFormValid(false);
        }
        setIsLoading(false);
    }



    return (
        <div className='p-5'>
            <div className="text-xl">Add Product</div>
            <form onSubmit={submitForm}>
                <div className="grid grid-flow-row gap-2 grid-cols-2">
                    <TextInputField
                        label="Product Code"
                        placeholder="Enter product code"
                        name='productCode'
                        value={values.productCode}
                        onChange={handleInputChange}
                        isInvalid={values.productCode?.length === 0}
                        validationMessage={!formValid && values.productCode?.length === 0 && 'Invalid Product Code'}
                    />
                    <TextInputField
                        label="Product Name"
                        placeholder="Enter product name"
                        name='productName'
                        value={values.productName}
                        onChange={handleInputChange}
                        isInvalid={values.productName?.length === 0}
                        validationMessage={!formValid && values.productName?.length === 0 && 'Invalid Name'}
                    />
                    <TextareaField
                        label="Description"
                        placeholder="Enter product description"
                        name='description'
                        value={values.description}
                        onChange={handleInputChange}
                        isInvalid={values.description?.length === 0}
                        validationMessage={!formValid && values.description?.length === 0 && 'Invalid Description'}
                    />

                    <DropDown
                        selectedItem={values.category}
                        name='category'
                        label='Category'
                        placeholder='Select Category'
                        items={Categories}
                        width='100%'
                        value={values.category}
                        onChange={(value: any) => handleInputChange({ target: { 'value': value, name: 'category' } })}
                        inputProps={{ isInvalid: values.category?.length === 0, invalidMsg: !formValid && values.category?.length === 0 }}
                        errormsg='Invalid Category'
                    />

                    {values.category === 'Fashion' && <InputList
                        sizeList={values.size}
                        formValid={formValid}
                        handleInputChange={handleInputChange}
                    />}

                    <TextInputField
                        label="Cost Price"
                        placeholder="Enter cost price"
                        name='costPrice'
                        type='number'
                        value={values.costPrice}
                        onChange={handleInputChange}
                        isInvalid={!values.costPrice || values.costPrice < 0}
                        validationMessage={!formValid && (!values.costPrice || values.costPrice < 0) && 'Invalid Cost Price'}
                    />
                    <TextInputField
                        label="Old Price"
                        placeholder="Enter old price"
                        name='oldPrice'
                        type='number'
                        value={values.oldPrice}
                        onChange={handleInputChange}
                        isInvalid={!values.oldPrice || values.oldPrice < 0}
                        validationMessage={!formValid && (!values.oldPrice || values.oldPrice < 0) && 'Invalid Old Price'}
                    />
                    <TextInputField
                        label="Selling Price"
                        placeholder="Enter selling price"
                        name='sellingPrice'
                        type='number'
                        value={values.sellingPrice}
                        onChange={handleInputChange}
                        isInvalid={!values.sellingPrice || values.sellingPrice < 0}
                        validationMessage={!formValid && (!values.sellingPrice || values.sellingPrice < 0) && 'Invalid Selling Price'}
                    />
                    <TextInputField
                        label="Delivery Charge"
                        placeholder="Enter delivery charge"
                        name='deliveryCharge'
                        type='number'
                        value={values.deliveryCharge}
                        onChange={handleInputChange}
                        isInvalid={!values.deliveryCharge || values.deliveryCharge < 0}
                        validationMessage={!formValid && (!values.deliveryCharge || values.deliveryCharge < 0) && 'Invalid Delivery Charge'}
                    />

                    <DropDown
                        selectedItem={values.status}
                        name='status'
                        label='Status'
                        placeholder='Select Status'
                        items={['Active', 'Inactive']}
                        width='100%'
                        value={values.status}
                        onChange={(value: any) => handleInputChange({ target: { 'value': value, name: 'status' } })}
                        inputProps={{ isInvalid: values.status?.length === 0, invalidMsg: !formValid && values.status?.length === 0 }}
                        errormsg='Invalid Status'
                    />

                    <TextInputField
                        label="Quantity"
                        placeholder="Enter stock"
                        name='quantity'
                        type='number'
                        value={values.quantity}
                        onChange={handleInputChange}
                        isInvalid={!values.quantity || values.quantity < 0}
                        validationMessage={!formValid && (!values.quantity || values.quantity < 0) && 'Invalid Quantity'}
                    />

                    <ImagePicker
                        handleInputChange={handleInputChange}
                        images={values.images}
                        formValid={formValid}
                    />
                </div>
                <Button type='submit' className='w-full' appearance="primary" isLoading={isLoading}>{!isEdit ? 'Submit' : 'Update'}</Button>

            </form>
        </div>
    )
}
